import { extendTheme} from '@chakra-ui/react'
import * as components from './components'
import * as foundations from './foundations'

const theme = extendTheme({
  ...foundations,
  components: { ...components },
  space: { '4.5': '1.125rem'},
})

export default theme;
