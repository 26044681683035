export default {
  gotham: 'Gotham-Bold',
  roboto:'Roboto, sans-serif',
  heading: 'Bellefair',
  headingalt: 'Gotham-Bold',
  text: 'InterVariable, -apple-system, system-ui, sans-serif',
  body: 'InterVariable, -apple-system, system-ui, sans-serif',
  lora:"Lora",
  f1:"Montserrat,sans-serif",
  
}
// Bellefair